.titulo-h2,
.titulo-h3 {
  font-family: "tahoma";
  font-weight: bold;
  color: rgb(6, 5, 80);
}

.botao .ant-btn-primary {
  background-color: rgb(6, 5, 80);
  border-color: rgb(6, 5, 80);
}
