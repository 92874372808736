.titulo-h2 {
  font-family: "tahoma";
  font-weight: bold;
  color: rgb(6, 5, 80);
}

.botaoEmp .ant-btn-primary {
  background-color: rgb(6, 5, 80);
  border-color: rgb(6, 5, 80);
  width: 100px;
}

.border-top {
  border-top: 4px solid black;
}

.border-left {
  border-left: 2px solid black;
}

.border-right {
  border-right: 2px solid black;
}

.border-botton {
  border-top: 4px solid black;
}
